import { NavLink } from '@remix-run/react';
import clsx from 'clsx';
import type { FC, HTMLAttributes } from 'react';
import type React from 'react';

export interface ProductFilterProps extends HTMLAttributes<HTMLElement> {
  filters: {
    label: string;
    icon: React.ReactNode;
    href: string;
  }[];
}

export const ProductFilter: FC<ProductFilterProps> = ({ className = '', ...props }) => {
  const { filters = [], ...rest } = props;

  return (
    <section className={clsx('max-w-screen-lg m-auto', className)} {...rest}>
      <div className="flex flex-wrap justify-center items-center gap-8 mt-5 mb-8">
        {filters?.map((filter, index) => {
          const { icon, label, href } = filter;

          const orderClass = index >= filters.length - 2 ? 'order-last md:order-none' : 'order-none';

          return (
            <NavLink
              key={index}
              className={clsx(
                'flex flex-col text-sm items-center justify-center text-center w-24 h-14 hover:bg-gray-200 rounded',
                orderClass,
              )}
              to={href}
            >
              <div>{icon}</div>
              <div>{label}</div>
            </NavLink>
          );
        })}
      </div>
    </section>
  );
};
